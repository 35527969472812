import firebase from "firebase/app";

const config = {
  apiKey: "AIzaSyAvCYv897SdeJUiVljsoN_mwJVIk89L7eQ",
  authDomain: "portfolio-1b9a7.firebaseapp.com",
  databaseURL: "https://portfolio-1b9a7.firebaseio.com",
  projectId: "portfolio-1b9a7",
  storageBucket: "portfolio-1b9a7.appspot.com",
  messagingSenderId: "806742353781"
};
firebase.initializeApp(config);
